import * as React from "react";
import "./MakeAPayment.css";
import { FunctionComponent } from "react";
import ReactHelmet from "react-helmet";
import {
  Button,
  Heading,
  Page,
  PageSection,
  Paragraph,
  Tile
} from "../components";
import * as PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

const PaymentOptionsTile = props => {
  return (
    <div className="tile payment-options--tile">
      {props.title && <Heading level={3}>{props.title}</Heading>}
      {props.description && <p>{props.description}</p>}
      {props.link && <Button href={props.link}>{props.linkText}</Button>}
      <br />
      {props.secondaryLink && (
        <Button href={`${props.secondaryLink}`} target="_blank">
          {props.secondaryLinkText}
        </Button>
      )}
      {props.children && props.children}
    </div>
  );
};

PaymentOptionsTile.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  secondaryLink: PropTypes.string,
  secondaryLinkText: PropTypes.string
};

const PaymentsPage: FunctionComponent = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulGeneralEditableField {
        edges {
          node {
            title
            pageSection
            childContentfulGeneralEditableFieldContentRichTextNode {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
      allContentfulPaymentOption {
        edges {
          node {
            title
            description {
              description
            }
            link
            linkText
            secondaryLink {
              file {
                url
              }
            }
            secondaryLinkText
          }
        }
      }
      allContentfulCollection(filter: { name: { eq: "Payment Options" } }) {
        edges {
          node {
            name
            entries {
              ... on ContentfulPaymentOption {
                title
                description {
                  description
                }
                link
                linkText
                secondaryLink {
                  file {
                    url
                  }
                }
                secondaryLinkText
              }
            }
          }
        }
      }
      paymentsImage: contentfulAsset(
        title: { eq: "Make a Payment > Payments" }
      ) {
        ...contentfulAssetFieldFragment
      }
      paymentMethodsImage: contentfulAsset(
        title: {
          eq: "Make a Payment > We also accept conventional payment methods"
        }
      ) {
        ...contentfulAssetFieldFragment
      }
    }

    fragment contentfulAssetFieldFragment on ContentfulAsset {
      description
      file {
        url
      }
    }
  `);
  const { paymentsImage, paymentMethodsImage } = data;
  const generalEditableFields = data.allContentfulGeneralEditableField.edges;
  const coupounBookContent = generalEditableFields.filter(
    field => field.node.pageSection === "paymentCouponReplacement"
  );
  const estatementContent = generalEditableFields.filter(
    field => field.node.pageSection === "paymentEStatement"
  );
  const conventionalPaymentContent = generalEditableFields.filter(
    field => field.node.pageSection === "paymentConventionalPayment"
  );
  const paymentOptions = data.allContentfulCollection.edges[0].node.entries;

  return (
    <Page layout="default" title="Make a Payment">
      <div className="payment--hero">
        <div>
          <img
            className="find-your-community--sidebar-img"
            src={paymentsImage.file.url}
            alt={paymentsImage.description}
          />
        </div>
        <div>
          <Heading level={1}>Payments</Heading>
          <p>
            {`In addition to conventional payments by mail or in person, payment may
          be made securely online with E-Check or Credit and Debit Card. In
          addition, automated monthly payments may be made by signing up for
          Hawaiiana's SurePay service.`}
          </p>
        </div>
      </div>
      <div className="section-gradient">
        <div className="section-white-gradient" />
        <Heading className="payment--heading" level={3}>
          Find an online method that works best for you
        </Heading>
        <div className="payment-options--wrapper">
          {paymentOptions.map((paymentOptionsContent, index) => {
            return (
              <PaymentOptionsTile
                key={`payment-tile-${index}`}
                title={paymentOptionsContent.title}
                description={paymentOptionsContent.description.description}
                link={paymentOptionsContent.link}
                linkText={paymentOptionsContent.linkText}
                secondaryLink={
                  paymentOptionsContent.secondaryLink
                    ? paymentOptionsContent.secondaryLink.file.url
                    : null
                }
                secondaryLinkText={paymentOptionsContent.secondaryLinkText}
              />
            );
          })}
        </div>

        <PageSection>
          {/* Questions Section */}
          <div
            style={{
              maxWidth: 720,
              margin: "auto"
            }}
          >
            <Tile>
              {coupounBookContent.map((content, index) => (
                <div key={`coupon-content-${index}`}>
                  <Heading level={3}>{content.node.title}</Heading>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        content.node
                          .childContentfulGeneralEditableFieldContentRichTextNode
                          .childContentfulRichText.html
                    }}
                  />
                </div>
              ))}
              <Button href="/coupon-book-replacement">Fill Out The Form</Button>
            </Tile>
            <Tile>
              {estatementContent.map((content, index) => (
                <div key={`community-question-${index}`}>
                  <Heading level={3}>{content.node.title}</Heading>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        content.node
                          .childContentfulGeneralEditableFieldContentRichTextNode
                          .childContentfulRichText.html
                    }}
                  />
                </div>
              ))}
              <Button href="/estatement-communities">
                eStatement Communities
              </Button>
            </Tile>
            <Tile>
              <Heading level={3}>Need to change your address?</Heading>
              <Paragraph>Fill out a change of address form online</Paragraph>
              <Button href="/change-address">Change Address</Button>
            </Tile>
            <Tile>
              <Heading level={3}>Need to cancel your SurePay?</Heading>
              <Paragraph>
                Cancel your SurePay automatic fee deductions here
              </Paragraph>
              <Button href="/surepay-cancellation-form">
                SurePay Cancellation Form
              </Button>
            </Tile>
          </div>
        </PageSection>
      </div>
      <div className="payment--conventional">
        <div>
          <img
            className="find-your-community--sidebar-img"
            src={paymentMethodsImage.file.url}
            alt={paymentMethodsImage.description}
          />
        </div>
        {conventionalPaymentContent.map((content, index) => (
          <div key={`conventional-payment-${index}`}>
            <Heading level={3}>{content.node.title}</Heading>
            <div
              dangerouslySetInnerHTML={{
                __html: content.node.childContentfulGeneralEditableFieldContentRichTextNode.childContentfulRichText.html.replace(
                  /\n/g,
                  "<br />"
                )
              }}
              style={{ padding: "0" }}
            />
          </div>
        ))}
      </div>
      <PageSection className="need-extra-help section-gradient">
        <Tile>
          <Heading level={3}>Need some extra help?</Heading>

          <div className="questions-container">
            <div>
              <p>
                Still have questions about submitting payments or handling fees?
              </p>
              <br />
              <Button href="/frequently-asked-questions">Search FAQs</Button>
            </div>
            <div>
              <p>Need to contact your Account Manager/Management Executive?</p>
              <br />
              <Button href="/find-a-community">Find Your Property</Button>
            </div>
            <div>
              <p>
                Have specific questions about your account? Contact an
                Accounting Specialist directly.
              </p>
              <p>
                <a href="tel:+18084405530">(808) 440-5530</a>
                <br />
                <a href="mailto:paymentinquiry@hmcmgt.com">
                  paymentinquiry@hmcmgt.com
                </a>
              </p>
              <br />
              <Button href="/find-a-community">Find Your Property</Button>
            </div>
          </div>
        </Tile>
      </PageSection>
    </Page>
  );
};

export default PaymentsPage;
